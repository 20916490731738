import React, { useMemo, useState } from "react";

import "./NavbarMenu.css";

import { BsFillXCircleFill } from "react-icons/bs";
import main_logo from "../../image/main-logo.png";
import { NavLink } from "react-router-dom";

function toggleClass(el) {
  var menuu = document.getElementById(el);
  console.log(menuu);
  if (menuu.className == "respnav") {
    menuu.className = "mobileshow";
  } else {
    menuu.className = "respnav";
  }
}

function toggleClassreverse(el) {
  var menuu = document.getElementById(el);
  console.log(menuu);
  if (menuu.className == "mobileshow" || menuu.className == "mobilehidden") {
    menuu.className = "respnav";
  } else {
    menuu.className = "respnav";
  }
}

function NavbarMenu(props) {
  const { isShow, handleClick } = props;
  const initialValues = {
    aboutUs: false,
    academics: false,
    beyond: false,
    career: false,
  };
  const [menuItem, setMenuItem] = useState(initialValues);
  const [data, setData] = useState([]);
  // console.log(isShow);
  const aboutUs_menu = [
    {
      title: "Vision & Mission",
      navigation: "vision-and-mission",
    },
    {
      title: "Message from Leadership",
      navigation: "message",
    },
  ];
  const academics_menu = [
    {
      title: "NEP 2020 Ready School ",
      navigation: "nep",
    },
    {
      title: "Curriculum ",
      navigation: "curriculum",
    },
    {
      title: "Pedagogical Approach",
      navigation: "pedagogi",
    },
    {
      title: "Life Skill Curriculum",
      navigation: "life-skill",
    },
    {
      title: "Assessment",
      navigation: "examination",
    },
    {
      title: "Skill & Vocational Subjects",
      navigation: "skill-vocational",
    },
    {
      title: "Results",
      navigation: "results",
    },
    {
      title: "Booklist",
      navigation: "books",
    },
  ];
  const beyond_menu = [
    {
      title: "House & Clubs",
      navigation: "house-clubs",
    },
    {
      title: "Student Council",
      navigation: "student-council",
    },
    {
      title: "Trips, Excursions & Field Visits",
      navigation: "trips",
    },
    {
      title: "NCC, Scouts & Guides",
      navigation: "ncc-scouts",
    },
  ];

  function handleMenu(name) {
    setMenuItem(() => {
      return { initialValues, [name]: true };
    });
  }
  useMemo(() => {
    setData(() => {
      return menuItem.aboutUs
        ? aboutUs_menu
        : menuItem.academics
        ? academics_menu
        : menuItem.beyond
        ? beyond_menu
        : null;
    });
  }, [menuItem]);

  const toggle = () => {
    return menuItem.aboutUs || menuItem.academics || menuItem.beyond
      ? ""
      : "hidden";
  };

  return (
    <div
      className="navbar_menu_main"
      style={{ display: isShow ? "block" : "none", overflow: "scroll" }}
    >
      <div>
        <div className="nav_cont">
          <div className="cross-div">
            <BsFillXCircleFill
              className="cursor"
              size={30}
              onClick={() => {
                handleClick();
                toggleClassreverse("aboutus");
                toggleClassreverse("acadsandcurr");
                toggleClassreverse("beyondcurr");
              }}
            />
          </div>
        </div>
      </div>
      <div className="menu_main">
        <div
          className="menu_item-1"
          style={{
            marginTop: "-55px",
          }}
        >
          <div
            className={`f-1-2 line-height-3 f-400 color-white mt-2 hover-cyan ${
              menuItem.aboutUs ? "active-line" : null
            }`}
            onClick={() => {
              handleMenu("aboutUs");
              toggleClass("aboutus");
              toggleClassreverse("acadsandcurr");
              toggleClassreverse("beyondcurr");
            }}
          >
            {" "}
            About Us
          </div>
          <div id={`aboutus`} className={`menu_item ${toggle()} respnav`}>
            {data?.map((item, index) => {
              return (
                <NavLink
                  to={`/${item.navigation}`}
                  onClick={() => handleClick()}
                >
                  <div className="f-1 f-400 color-white mt-1 hover-underline-animation">
                    {item.title}
                  </div>
                </NavLink>
              );
            })}
          </div>
          <div
            className={`f-1-2 line-height-3 f-400 color-white mt-1 hover-cyan ${
              menuItem.academics ? "active-line" : null
            }`}
            onClick={() => {
              handleMenu("academics");
              toggleClass("acadsandcurr");
              toggleClassreverse("aboutus");
              toggleClassreverse("beyondcurr");
            }}
          >
            Academics & Curriculum
          </div>
          <div
            id={`acadsandcurr`}
            className={`menu_itemone ${toggle()} respnav`}
          >
            {data?.map((item, index) => {
              return (
                <NavLink
                  to={`/${item.navigation}`}
                  onClick={() => handleClick()}
                >
                  <div className="f-1 f-400 color-white mt-1 hover-underline-animation">
                    {item.title}
                  </div>
                </NavLink>
              );
            })}
          </div>
          <div
            className={`f-1-2 line-height-3 f-400 color-white mt-1 hover-cyan ${
              menuItem.beyond ? "active-line" : null
            }`}
            onClick={() => {
              handleMenu("beyond");
              toggleClass("beyondcurr");
              toggleClassreverse("aboutus");
              toggleClassreverse("acadsandcurr");
            }}
          >
            Beyond Curriculum
          </div>
          <div id={`beyondcurr`} className={`menu_itemtwo ${toggle()} respnav`}>
            {data?.map((item, index) => {
              return (
                <NavLink
                  to={`/${item.navigation}`}
                  onClick={() => handleClick()}
                >
                  <div className="f-1 f-400 color-white mt-1 hover-underline-animation">
                    {item.title}
                  </div>
                </NavLink>
              );
            })}
          </div>
          <a href="/static/media/AAC.268d286fd6ad33ae28c9.xlsx">
            <div
              className={`f-1-2 line-height-3 f-400 color-white mt-1 hover-cyan `}
            >
              Institutional Planner
            </div>
          </a>
          <NavLink to={"/sports"}>
            <div
              className={`f-1-2 line-height-3 f-400 color-white mt-1 hover-cyan`}
              onClick={() => {
                handleClick();
              }}
            >
              Sports & Fitness
            </div>
          </NavLink>
          <NavLink to={"/infrastructure"}>
            <div
              className={`f-1-2 line-height-3 f-400 color-white mt-1 hover-cyan `}
              onClick={() => {
                handleClick();
              }}
            >
              Infrastructure & Facilities
            </div>
          </NavLink>
          <NavLink to="/awards">
            <div
              className={`f-1-2 line-height-3 f-400 color-white mt-1 hover-cyan `}
              onClick={() => {
                handleClick();
              }}
            >
              Achievements & Laurels
            </div>
          </NavLink>
          <NavLink to={"/events"}>
            <div
              className={`f-1-2 line-height-3 f-400 color-white mt-1 hover-cyan `}
              onClick={() => {
                handleMenu("news");
                handleClick();
              }}
            >
              Events & Happenings
            </div>
          </NavLink>
          <NavLink to={"/news"}>
            <div
              className={`f-1-2 line-height-3 f-400 color-white mt-1 hover-cyan `}
              onClick={() => {
                handleClick();
              }}
            >
              News & Media Coverage
            </div>
          </NavLink>
          <NavLink to={"/inspired"}>
            <div
              className={`f-1-2 line-height-3 f-400 color-white mt-1 hover-cyan `}
              onClick={() => {
                handleClick();
              }}
            >
              Illustrious Alumni
            </div>
          </NavLink>
          <NavLink to={"/parents"}>
            <div
              className={`f-1-2 line-height-3 f-400 color-white mt-1 hover-cyan `}
              onClick={() => {
                handleClick();
              }}
            >
              Parents' & Students' Voice
            </div>
          </NavLink>
          <NavLink to="/admissions">
            <div
              className={`f-1-2 line-height-3 f-400 color-white mt-1 hover-cyan `}
              onClick={() => {
                handleMenu("news");
                handleClick();
              }}
            >
              Admissions
            </div>
          </NavLink>
          <NavLink to={"/faculty-staff"}>
            <div
              className={`f-1-2 line-height-3 f-400 color-white mt-1 hover-cyan `}
              onClick={() => {
                handleMenu("news");
                handleClick();
              }}
            >
              Faculty & Staff
            </div>
          </NavLink>
          <NavLink to="/contact-us">
            <div
              className={`f-1-2 line-height-3 f-400 color-white mt-1 hover-cyan`}
              onClick={() => {
                handleMenu("press");
                handleClick();
              }}
            >
              Contact Us
            </div>
          </NavLink>
          <NavLink to="/mandatory-disclosures">
            <div
              className={`f-1-2 line-height-3 f-400 color-white hover-cyan showmobile`}
              onClick={() => {
                handleMenu("press");
                handleClick();
              }}
            >
              Mandatory Disclosures
            </div>
          </NavLink>
        </div>
        <div className={`menu_item ${toggle()} mobilehidden`}>
          {data?.map((item, index) => {
            return (
              <NavLink to={`/${item.navigation}`} onClick={() => handleClick()}>
                <div className="f-1 f-400 color-white mt-1 hover-underline-animation">
                  {item.title}
                </div>
              </NavLink>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default NavbarMenu;
