import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';


const root = ReactDOM.createRoot(document.getElementById('root'));
// const injectGA = () => {
//   if (typeof window == 'undefined') {
//     return;
//   }
//   window.dataLayer = window.dataLayer || [];
//   function gtag() {
//     window.dataLayer.push(arguments);
//   }
//   gtag('js', new Date());

//   gtag('config', 'YOUR_TRACKING_ID');
// };
root.render(
  <React.StrictMode>
    <App />
    {/* <script async src="https://www.googletagmanager.com/gtag/js?id=G-53PXRKEXLD"></script>
    <script>{injectGA()}</script> */}
  </React.StrictMode>
);

