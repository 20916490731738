import Modal from "react-bootstrap/Modal";
import React from "react";
import { Button} from "antd";

import "./HomeModal.css";
import Slider from "react-slick";
import { HomeModalContent } from ".";
import modalVideo from "../../video/AdmissionTemplate.mp4";
import kidzana from "../../image/kidzana.png";
import kizanapdf from"../../pdf/Kidzania_23rd_Nov'23[1].pdf";

const HomeModal = ({ show, onHide }) => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 7000,
          autoplay: true,
          infinite: true,
          dots: false,
        },
      },
    ],
  };
  return (
    <></>
    // <Modal
    //   show={show}
    //   onHide={onHide}
    //   size="lg"
    //   className="home-modal"
    //   aria-labelledby="contained-modal-title-vcenter"
    //   centered
    //   style={{ backdropFilter: "blur(0px)", zIndex: "99999999" }}
    // >
    //   <Modal.Header closeButton></Modal.Header>
    //   {/* <video loop autoPlay muted>
    //             <source src={modalVideo} type="video/mp4" />
    //         </video> */}
    //   {/* <a href="https://forms.gle/V3BTif9K3igU2ge79"> */}

    //   <img src={kidzana} />
    //   {/* <button>Re</button> */}
    //   {/* </a> */}
    //  <div className="buttons-details">
    //     <a href="https://forms.gle/V3BTif9K3igU2ge79"> 
    //     <Button type="primary" className="bot" size="large">Register Now</Button>
    //     </a>
    //     <a href={require("../../pdf/Kidzania_23rd_Nov'23[1].pdf")} target="_blank">
    //     <Button type="primary" className="bot" size="large">Know More</Button>
    //     </a>
    //     </div>
    // </Modal>
  );
};

export default HomeModal;
