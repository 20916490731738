import React from "react";
import NewNavbar from "../NavBar/NewNavbar";
import { useState } from "react";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import Footer from "../Footer/Footer";
import BlogPost from "./blogPosts";
const Blog = () => {
  const [isShow, setIsShow] = useState(false);

  const handleClick = () => {
    setIsShow(!isShow);
  };

  const posts = [
    {
      id: 5,
      title: "Inculcating an Entrepreneurial Mindset in Education: Why It Matters",
      date: "Nov 8,2024",
      readTime: "3 min",
      imageUrl: "./blogpicnew.webp",
      excerpt: "Designing the Future may sound ambitious, but it's an essential shift in today’s educational landscape. To truly prepare students for tomorrow’s world, schools can transform traditional teaching by nurturing entrepreneurial mindsets. This approach involves cultivating creativity, critical thinking, and resilience, empowering students to recognize opportunities and tackle real-world challenges with confidence."
      ,
    },

    {
      id: 3,
      title:
        "Unlocking the Future of Education: A Beginner's Guide to Digitalization in the School Classroom",
      date: "May 27,2024 ",
      readTime: "2 min",
      imageUrl: "../Digitalizationin school classroom.jpg",
      excerpt:
        "In the fast-evolving landscape of education, a powerful wave is reshaping traditional classrooms: ",
    },
    {
      id: 4,
      title:
        "Key Factors to Consider When Choosing the Best School in Faridabad for Your Child's Admission in 2024",
      date: "Apr 25,2024",
      readTime: "3 min",
      imageUrl: "../11.jpeg",
      excerpt:
        "Choosing the right school for your child is one of the most important decisions you can make",
    },
    {
      id: 1,
      title: "Interdisciplinary Demand of the Future",
      date: "Apr 10, 2024",
      readTime: "2 min",
      imageUrl: "../Blog 1.jpg",
      excerpt: `Interdisciplinary demand refers to the increasing need for individuals to possess skills and expertise across multiple disciplines.
          As society faces complex challenges and rapid advancements in various fields, the approach of specialized knowledge is no longer sufficient. Instead, there is a growing recognition of the value of integrating insights and methodologies from different disciplines to address multifaceted problems effectively.
          The future is undeniably interdisciplinary, characterized by complex challenges that require collaborative solutions drawing from multiple fields of knowledge. Embracing this interdisciplinary future holds immense promise for addressing pressing global issues, driving innovation, and fostering positive social, economic, and environmental outcomes.
          By embracing interdisciplinary collaboration, we can harness the collective expertise, creativity, and perspectives of individuals from diverse backgrounds to tackle complex problems from multiple angles. This approach enables us to develop holistic and comprehensive solutions that are more effective, sustainable, and resilient in the face of uncertainty and change.
          Moreover, interdisciplinary collaboration fosters a culture of innovation, creativity, and continuous learning, where individuals are encouraged to explore new ideas, challenge conventional thinking, and cross traditional boundaries between disciplines. This not only leads to breakthrough discoveries and insights but also cultivates a deeper understanding of the interconnectedness of knowledge across different domains.
          As we navigate the challenges of the 21st century – from climate change and public health crises to technological disruptions and social inequality – embracing the interdisciplinary future is not just an option but a necessity. It requires us to break down silos, bridge divides, and cultivate a spirit of openness, curiosity, and collaboration across disciplines.
          In doing so, we can unlock the full potential of interdisciplinary collaboration to drive transformative change, shape a more sustainable and equitable future, and address the grand challenges facing humanity. By embracing the interdisciplinary future, we can build a world where diversity is celebrated, innovation thrives, and collective action leads to positive impact on a global scale.
          Importance of Interdisciplinary Skills
          1. Problem Solving: Interdisciplinary skills enable individuals to approach problems from multiple perspectives, leading to more innovative and comprehensive solutions.
          2. Collaboration: In interdisciplinary environments, collaboration between experts from diverse fields becomes essential. Individuals with interdisciplinary skills can communicate effectively across disciplines, fostering teamwork and synergy.
          3. Adaptability: Rapid advancements in technology and evolving societal needs require individuals to adapt quickly. Interdisciplinary skills provide a foundation for lifelong learning and versatility in navigating changing landscapes.
          4. Innovation: Breakthroughs often occur at the intersection of different disciplines. Individuals with interdisciplinary skills are better positioned to identify opportunities for innovation and create novel solutions.
          5. Holistic Understanding: Complex issues such as climate change, healthcare, and poverty require a holistic understanding that encompasses various factors. Interdisciplinary skills facilitate a broader perspective, allowing individuals to consider the interconnectedness of different elements.
          Developing an interdisciplinary mind-set is essential for addressing complex challenges and fostering innovation. Here's a guide to cultivating such a mind-set:
          1. Embrace Curiosity: Cultivate a genuine curiosity about different fields of knowledge. Be open to exploring topics beyond your immediate area of expertise and seek to understand how they intersect with your own interests.
          2. Learn Continuously: Commit to lifelong learning. Take courses, attend workshops, read widely, and engage with experts from diverse disciplines to expand your knowledge base and develop a broader perspective.
          3. Seek Connections: Look for connections between seemingly unrelated fields. Practice thinking across disciplines and recognize patterns and analogies that can inform your work or problem-solving approach.
          4. Collaborate Widely: Actively seek out opportunities to collaborate with people from diverse backgrounds and disciplines. Working with others who bring different perspectives can lead to more innovative and effective solutions.
          5. Develop Critical Thinking Skills: Cultivate critical thinking skills that allow you to evaluate information, analyze complex problems, and consider multiple viewpoints. This will help you navigate the complexities of interdisciplinary work.
          6. Communicate Effectively: Learn to communicate your ideas clearly and persuasively across disciplines. Develop the ability to translate complex concepts into language that is accessible to people with different backgrounds and expertise.
          7. Be Flexible and Adaptable: Embrace uncertainty and be willing to adapt your thinking and approach as new information emerges. Interdisciplinary work often requires flexibility and the ability to navigate ambiguity.
          8. Practice Integrative Thinking: Practice integrative thinking by synthesizing ideas from multiple disciplines to generate new insights or solutions. Look for opportunities to combine different approaches in creative ways.
          9. Embrace Diversity: Recognize the value of diversity in interdisciplinary work. Embrace diverse perspectives, experiences, and ways of thinking, as they can enrich the collaborative process and lead to more innovative outcomes.
          10. Stay Humble: Recognize that no one person can be an expert in everything. Stay humble and approach interdisciplinary work with a willingness to listen, learn, and collaborate with others.
          By adopting these practices and attitudes, you can develop an interdisciplinary mind-set that enables you to tackle complex challenges, foster innovation, and make meaningful contributions across disciplines.`,
      author: "Ms. Debaroti Bose",
    },
    {
      id: 2,
      title:
        "Embracing Creativity: The Transformative Role of Arts in STEAM Education",
      date: "Apr 10, 2024",
      readTime: "2 min",
      imageUrl: "../Blog no. 2.jpg",

      excerpt:
        "In the realm of education, a powerful movement is underway to integrate the arts into STEM disciplines, creating a holistic approach known as STEAM (Science,Technology, Engineering, Arts, and Mathematics) education. If you've ever wondered about the significance of art in STEAM or the benefits of combining these seemingly disparate fields, you're about to embark on an enlightening journey. In this comprehensive exploration, we'll delve into the symbiotic relationship between arts and STEM, uncovering the profound impact it has on fostering creativity, innovation, and well-rounded individuals poised for success in the 21st century.",
    },
  ];

  return (
    <>
      <NewNavbar handleClick={handleClick} />
      {/* <Navbar isScrolled={isScrolled} handleClick={handleClick} /> */}
      {/* <NavbarMenu isShow={isShow} handleClick={handleClick} /> */}
      <div></div>
      <div className="home_mai">
        {/* <Navbar isScrolled={true} handleClick={handleClick} /> */}

        <div className="blog-list">
          {posts.map((blog, index) => (
            <BlogPost key={index} {...blog} />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Blog;
