import React from "react";
import { Helmet } from "react-helmet";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
// Custom CSS for styling

const FAQAccordion = () => {
  const faqs = [
    {
      question:
        "What makes Hermann Gmeiner School one of the top schools in Faridabad?",
      answer: `Hermann Gmeiner School is renowned for its commitment to academic excellence, holistic development, and a supportive learning environment. Our consistent 100% results reflect our dedication to maintaining high academic standards. Our curriculum, which observes a pedagogical approach blended with developing 21st-century skills, ensures that our students are well-prepared for the future. Our experienced faculty, modern facilities, and comprehensive educational programs place us among the top schools in Faridabad.`,
    },
    {
      question: "Are admissions open for the upcoming academic session?",
      answer: `Yes, admissions are currently open for the upcoming academic session. We invite prospective students and parents to apply early as seats are limited. Please visit our admissions page for detailed information on the application process.\nhttps://hermanngmeinerschoolfaridabad.org/admissions`,
    },
    {
      question:
        "What is the admission process at Hermann Gmeiner School, Faridabad?",
      answer: `Our admission process is straightforward. It includes filling out an application form, submitting required documents, and an interaction/interview with the student and parents. For detailed steps and required documents, please refer to our admissions section.\nhttps://hermanngmeinerschoolfaridabad.org/admissions`,
    },
    {
      question:
        "What extracurricular activities are available at Hermann Gmeiner School?",
      answer: `We offer a wide range of extracurricular activities including sports, music, dance, drama, art, and various clubs. These activities are designed to enhance the overall development of our students and provide them with opportunities to explore their interests while developing essential 21st-century skills such as teamwork, leadership, and communication.`,
    },
    {
      question:
        "How does Hermann Gmeiner School support students’ academic and personal growth?",
      answer: `We have a dedicated team of teachers and counselors who work closely with students to support their academic achievements and personal development. Our curriculum is designed to foster critical thinking, creativity, and emotional intelligence, ensuring that students are well-equipped with the skills needed for the modern world.`,
    },
    {
      question: "What are the school’s timings?",
      answer: `The school operates from 8am to 2pm , Monday to Friday. For specific timings for different classes and sections, please refer to our school calendar or contact the school office.`,
    },
    {
      question: "How can parents stay informed about their child’s progress?",
      answer: `We believe in maintaining open communication with parents. Regular parent-teacher meetings, progress reports, and our online parent portal provide updates on your child’s academic performance and overall development.`,
    },
    {
      question:
        "What measures are taken by Hermann Gmeiner School to ensure the safety and well-being of students as per CBSE guidelines?",
      answer: `We follow CBSE guidelines to ensure the safety and well-being of our students, which include:
      • Comprehensive safety protocols and emergency procedures.
      • Regular health check-ups and counseling services.
      • Anti-bullying policies and awareness programs.
      • Strict adherence to hygiene and sanitation standards.`,
    },
    {
      question:
        "What resources are available at Hermann Gmeiner School to support the CBSE curriculum?",
      answer: `Our school is equipped with:
      • Modern classrooms with digital learning tools.
      • Well-stocked libraries with CBSE-recommended books.
      • State-of-the-art science and computer labs.
      • Sports facilities and playgrounds.
      • Art, music, and dance studios.`,
    },
    {
      question:
        "How does Hermann Gmeiner School support students in preparing for board exams?",
      answer: `Our experienced faculty provides dedicated support to students preparing for CBSE board exams through:
      • Regular mock tests and practice sessions.
      • Special doubt-clearing sessions.
      • Comprehensive study materials and resources.
      • Personal counseling and guidance.`,
    },
    {
      question: "How is Hermann Gmeiner School aligned with NEP 2020?",
      answer: `Hermann Gmeiner School is fully aligned with NEP 2020 and has adopted its guidelines to enhance our educational approach. This includes incorporating a multidisciplinary curriculum, fostering critical thinking, and emphasizing the development of 21st-century skills such as creativity, collaboration, and communication.`,
    },
    {
      question:
        "What changes have been made in the curriculum at Hermann Gmeiner School to align with NEP 2020?",
      answer: `Our curriculum has been updated to include:
      • A multidisciplinary approach to subjects.
      • Greater emphasis on experiential and project-based learning.
      • Introduction of coding, vocational education, and life skills from early grades.
      • Integration of arts, sports, and vocational skills within the regular curriculum.
      • Use of technology and digital tools to enhance learning.`,
    },
  ];

  return (
    <Container maxWidth="md" className="faq-container">
      
      <Helmet>
        {/* <title>FAQs - Hermann Gmeiner School</title> */}
        <meta name="description" content="Frequently asked questions about Hermann Gmeiner School in Faridabad." />
        <meta name="keywords" content="FAQ, Hermann Gmeiner School, Faridabad, admissions, curriculum" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [
                {
                  "@type": "Question",
                  "name": "What makes Hermann Gmeiner School one of the top schools in Faridabad?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Hermann Gmeiner School is renowned for its commitment to academic excellence, holistic development, and a supportive learning environment. Our consistent 100% results reflect our dedication to maintaining high academic standards. Our curriculum, which observes a pedagogical approach blended with developing 21st-century skills, ensures that our students are well-prepared for the future. Our experienced faculty, modern facilities, and comprehensive educational programs place us among the top schools in Faridabad."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Are admissions open for the upcoming academic session?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, admissions are currently open for the upcoming academic session. We invite prospective students and parents to apply early as seats are limited. Please visit our admissions page for detailed information on the application process: https://hermanngmeinerschoolfaridabad.org/admissions"
                  }
                },
                {
                  "@type": "Question",
                  "name": "What is the admission process at Hermann Gmeiner School, Faridabad?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Our admission process is straightforward. It includes filling out an application form, submitting required documents, and an interaction/interview with the student and parents. For detailed steps and required documents, please refer to our admissions section: https://hermanngmeinerschoolfaridabad.org/admissions"
                  }
                },
                {
                  "@type": "Question",
                  "name": "What extracurricular activities are available at Hermann Gmeiner School?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "We offer a wide range of extracurricular activities including sports, music, dance, drama, art, and various clubs. These activities are designed to enhance the overall development of our students and provide them with opportunities to explore their interests while developing essential 21st-century skills such as teamwork, leadership, and communication."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How does Hermann Gmeiner School support students’ academic and personal growth?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "We have a dedicated team of teachers and counselors who work closely with students to support their academic achievements and personal development. Our curriculum is designed to foster critical thinking, creativity, and emotional intelligence, ensuring that students are well-equipped with the skills needed for the modern world."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What are the school’s timings?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The school operates from 8:00 AM to 3:00 PM, Monday to Friday. For specific timings for different classes and sections, please refer to our school calendar or contact the school office."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How can parents stay informed about their child’s progress?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "We believe in maintaining open communication with parents. Regular parent-teacher meetings, progress reports, and our online parent portal provide updates on your child’s academic performance and overall development."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What measures are taken by Hermann Gmeiner School to ensure the safety and well-being of students as per CBSE guidelines?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "We follow CBSE guidelines to ensure the safety and well-being of our students, which include: 1. Comprehensive safety protocols and emergency procedures. 2. Regular health check-ups and counseling services. 3. Anti-bullying policies and awareness programs. 4. Strict adherence to hygiene and sanitation standards."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What resources are available at Hermann Gmeiner School to support the CBSE curriculum?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Our school is equipped with: 1. Modern classrooms with digital learning tools. 2. Well-stocked libraries with CBSE-recommended books. 3. State-of-the-art science and computer labs. 4. Sports facilities and playgrounds. 5. Art, music, and dance studios."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How does Hermann Gmeiner School support students in preparing for board exams?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Our experienced faculty provides dedicated support to students preparing for CBSE board exams through: 1. Regular mock tests and practice sessions. 2. Special doubt-clearing sessions. 3. Comprehensive study materials and resources. 4. Personal counseling and guidance."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How is Hermann Gmeiner School aligned with NEP 2020?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Hermann Gmeiner School is fully aligned with NEP 2020 and has adopted its guidelines to enhance our educational approach. This includes incorporating a multidisciplinary curriculum, fostering critical thinking, and emphasizing the development of 21st-century skills such as creativity, collaboration, and communication."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What changes have been made in the curriculum at Hermann Gmeiner School to align with NEP 2020?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Our curriculum has been updated to include: 1. A multidisciplinary approach to subjects. 2. Greater emphasis on experiential and project-based learning. 3. Introduction of coding, vocational education, and life skills from early grades. 4. Integration of arts, sports, and vocational skills within the regular curriculum. 5. Use of technology and digital tools to enhance learning. For any further queries, please do not hesitate to contact us at 0129-4088574. We look forward to welcoming your child to Hermann Gmeiner School, Faridabad – where a cutting-edge curriculum meets holistic development, preparing students for a successful future."
                  }
                }
              ]
            }
          `}
        </script>
      </Helmet>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        className="faq-heading"
      >
        {/* br tag added to give it a break */}
        Welcome to the FAQ section of <br></br>Hermann Gmeiner School,
        Faridabad!
      </Typography>
      <Typography align="center" gutterBottom className="faq-subheading">
        Here, you'll find answers to the most frequently asked questions about
        our school, admissions, facilities, and more. If you have any other
        queries, please feel free to contact us directly.
      </Typography>
      {faqs.map((faq, index) => (
        <Accordion
          key={index}
          className="faq-accordion"
          // sx={{ backgroundColor: "#ADD8E6", color: "#ADD8E6" }}  // Changes made here
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
            className="faq-header"
          >
            <QuestionAnswerIcon className="faq-icon" />
            <Typography className="faq-question">{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails className="faq-body">
            <Typography className="faq-answer">{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
};
export default FAQAccordion;

