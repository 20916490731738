// import React, { useState } from "react";
// import HouseAndClubs from "../BeyondCurrciular/HouseAndClubs";
// import Footer from "../Footer/Footer";
// import Landing from "../Landing/Landing";
// import Navbar from "../NavBar/Navbar";
// import NavbarMenu from "../NavbarMenu/NavbarMenu";
// import harbrochure1 from "../../image/broucher/harbrochure1.jpg";
// import harbrochure2 from "../../image/broucher/harbrochure2.jpg";
// import { Helmet } from "react-helmet";
// import "./Content.css";
// import ContentMain from "./ContentMain";
// import NewNavbar from "../NavBar/NewNavbar";
// import { Link } from "react-router-dom";
// function Content(props) {
//   const {
//     cover,
//     sidebar_data,
//     data,
//     title,
//     icon,
//     alumni_data,
//     curriculum_data,
//     awards_data,
//     news_data,
//     side_heading,
//     side_sub_heading,
//     link,
//     showBubble,
//     headingColor,
//     topicon,
//     flaticon,
//     canonicalUrl,
//   } = props;
//   const location = window.location.pathname;
//   const [isScrolled, setIsScrolled] = useState(false);
//   const [isShow, steIsShow] = useState(false);
//   const handleScroll = (e) => {
//     if (e.target.scrollTop > 0 && !isScrolled) {
//       setIsScrolled(true);
//     }
//     if (e.target.scrollTop === 0) {
//       setIsScrolled(false);
//     }
//   };
//   const handleClick = () => {
//     steIsShow(!isShow);
//     console.log(isShow);
//   };

//   return (
//     <div className="content_main" onScroll={handleScroll}>
//       {/* <Navbar
//         isScrolled={isScrolled}
//         handleClick={handleClick} 
//         custom_class="logo"
//       /> */}
//    <Helmet>
//   <link rel="canonical" href={canonicalUrl || window.location.href} />
// </Helmet>

//       <NewNavbar handleClick={handleClick} />
//       <NavbarMenu isShow={isShow} handleClick={handleClick} />
//       <Landing video={false} cover={cover} />

//       <ContentMain
//         sidebar_data={sidebar_data}
//         data={data}
//         icon={icon}
//         title={title}
//         alumni_data={alumni_data}
//         news_data={news_data}
//         curriculum_data={curriculum_data}
//         awards_data={awards_data}
//         side_heading={side_heading}
//         side_sub_heading={side_sub_heading}
//         link={link}
//         showBubble={showBubble}
//         headingColor={headingColor}
//         topicon={topicon}
//         flaticon={flaticon}
//       />
//       {location.includes("admissions") && (
//         <>
//           <div
//             style={{
//               marginBottom: "5%",
//               marginTop: "2%",
//             }}
//           >
//             <Link
//               to="/contact-us"
//               className="navbarItem"
//               style={{
//                 backgroundColor: "rgb(30 154 214 / 1)",
//                 marginLeft: "26%",
//                 padding: "1rem",
//                 borderRadius: "10px",
//               }}
//             >
//               Apply Now
//             </Link>
//           </div>
//           <div
//             style={{
//               marginLeft: "20%",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//           >
//             <img
//               style={{ width: "400px", margin: "10px" }}
//               src={harbrochure1}
//               alt=""
//             />
//             <img style={{ width: "400px" }} src={harbrochure2} alt="" />
//           </div>
//         </>
//       )}

//       <Footer />
//     </div>
//   );
// }

// export default Content;

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"; // import useLocation for detecting route changes
import HouseAndClubs from "../BeyondCurrciular/HouseAndClubs";
import Footer from "../Footer/Footer";
import Landing from "../Landing/Landing";
import Navbar from "../NavBar/Navbar";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import harbrochure1 from "../../image/broucher/harbrochure1.jpg";
import harbrochure2 from "../../image/broucher/harbrochure2.jpg";
import { Helmet } from "react-helmet";
import "./Content.css";
import ContentMain from "./ContentMain";
import NewNavbar from "../NavBar/NewNavbar";
import { Link } from "react-router-dom";

function Content(props) {
  const {
    cover,
    sidebar_data,
    data,
    title,
    icon,
    alumni_data,
    curriculum_data,
    awards_data,
    news_data,
    side_heading,
    side_sub_heading,
    link,
    showBubble,
    headingColor,
    topicon,
    flaticon,
    canonicalUrl,
  } = props;

  const location = useLocation(); // get the current location using useLocation
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShow, setIsShow] = useState(false);

  // Detect scroll events
  const handleScroll = (e) => {
    if (e.target.scrollTop > 0 && !isScrolled) {
      setIsScrolled(true);
    }
    if (e.target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };

  // Toggle the visibility of the NavbarMenu
  const handleClick = () => {
    setIsShow(!isShow);
  };

  useEffect(() => {
    // This effect runs whenever the location (route) changes
    // It updates the canonical URL to match the new location
    document.querySelector("link[rel='canonical']").setAttribute("href", canonicalUrl || window.location.href);
  }, [location, canonicalUrl]);

  return (
    <div className="content_main" onScroll={handleScroll}>
      {/* Helmet to manage head */}
      <Helmet>
        <link rel="canonical" href={canonicalUrl || window.location.href} />
      </Helmet>

      <NewNavbar handleClick={handleClick} />
      <NavbarMenu isShow={isShow} handleClick={handleClick} />
      <Landing video={false} cover={cover} />

      <ContentMain
        sidebar_data={sidebar_data}
        data={data}
        icon={icon}
        title={title}
        alumni_data={alumni_data}
        news_data={news_data}
        curriculum_data={curriculum_data}
        awards_data={awards_data}
        side_heading={side_heading}
        side_sub_heading={side_sub_heading}
        link={link}
        showBubble={showBubble}
        headingColor={headingColor}
        topicon={topicon}
        flaticon={flaticon}
      />

      {location.pathname.includes("admissions") && (
        <>
          <div
            style={{
              marginBottom: "5%",
              marginTop: "2%",
            }}
          >
            <Link
              to="/contact-us"
              className="navbarItem"
              style={{
                backgroundColor: "rgb(30 154 214 / 1)",
                marginLeft: "26%",
                padding: "1rem",
                borderRadius: "10px",
              }}
            >
              Apply Now
            </Link>
          </div>
          <div
            style={{
              marginLeft: "20%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{ width: "400px", margin: "10px" }}
              src={harbrochure1}
              alt=""
            />
            <img style={{ width: "400px" }} src={harbrochure2} alt="" />
          </div>
        </>
      )}

      <Footer />
    </div>
  );
}

export default Content;
