import React, { useLayoutEffect, useRef, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import Footer from "../Footer/Footer";
import Navbar from "../NavBar/Navbar";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import {
  disclosure_data_documents,
  disclosure_data_general,
  disclosure_data_results,
  disclosure_data_results_10,
  disclosure_data_results_12,
  disclosure_data_school,
  disclosure_data_staff,
} from "../../variable";

import NewNavbar from "../NavBar/NewNavbar";

function Sitemap() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShow, steIsShow] = useState(false);
  const handleScroll = (e) => {
    console.log(e);
    if (e.target.scrollTop > 0 && !isScrolled) {
      setIsScrolled(true);
    }
    if (e.target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };
  const handleClick = () => {
    steIsShow(!isShow);
    console.log(isShow);
  };
  return (
    <div className="home_main" onScroll={handleScroll}>
      <NewNavbar handleClick={handleClick} />
      {/* <Navbar isScrolled={true} handleClick={handleClick} /> */}
      <NavbarMenu isShow={isShow} handleClick={handleClick} />
      <div
        style={{
          marginTop: "100px",
          marginLeft: "25px",
          fontSize: "20px",
          fontWeight: "700",
        }}
      ></div>
      <div
        className="mandatory_heading"
        style={{ fontSize: "20px", fontWeight: "700" }}
      >
        A. GENERAL INFORMATION
      </div>

      <div
        className="mandatory_table"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <ul>
          <li>
            <a href="https://hermanngmeinerschoolfaridabad.org/">Home</a>
          </li>
          <li>
            <a href="https://hermanngmeinerschoolfaridabad.org/mandatory-disclosures">
              Mandatory Disclosure
            </a>
          </li>
          <li>
            <a href="https://hermanngmeinerschoolfaridabad.org/Fees-payment">
              Fees-Payment
            </a>
          </li>
          <li>
            <a href="https://hermanngmeinerschoolfaridabad.org/parent-login">
              Parent Login
            </a>
          </li>
          <li>
            <a href="https://hermanngmeinerschoolfaridabad.org/student-login">
              Student Login
            </a>
          </li>
          <li>
            Know more
            <ul>
              <li>
                About us
                <ul>
                  <li>
                    <a href="https://hermanngmeinerschoolfaridabad.org/vision-and-mission">
                      Vision and Mission
                    </a>
                  </li>
                  <li>
                    <a href="https://hermanngmeinerschoolfaridabad.org/message">
                      Message from Leadership
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                Academics &amp; Curriculum
                <ul>
                  <li>
                    <a href="https://hermanngmeinerschoolfaridabad.org/nep">
                      NEP 2020 Ready School
                    </a>
                  </li>
                  <li>
                    <a href="https://hermanngmeinerschoolfaridabad.org/curriculum">
                      Curriculum
                    </a>
                  </li>
                  <li>
                    <a href="https://hermanngmeinerschoolfaridabad.org/pedagogi">
                      Pedagogical Approach
                    </a>
                  </li>
                  <li>
                    <a href="https://hermanngmeinerschoolfaridabad.org/life-skill">
                      Life Skill Curriculum
                    </a>
                  </li>
                  <li>
                    <a href="https://hermanngmeinerschoolfaridabad.org/examination">
                      Assessment
                    </a>
                  </li>
                  <li>
                    <a href="https://hermanngmeinerschoolfaridabad.org/skill-vocational">
                      Skill &amp; Vocational Subjects
                    </a>
                  </li>
                  <li>
                    <a href="https://hermanngmeinerschoolfaridabad.org/results">
                      Results
                    </a>
                  </li>
                  <li>
                    <a href="https://hermanngmeinerschoolfaridabad.org/books">
                      Booklist
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                Beyond Curriculum
                <ul>
                  <li>
                    <a href="https://hermanngmeinerschoolfaridabad.org/house-clubs">
                      House &amp; Clubs
                    </a>
                  </li>
                  <li>
                    <a href="https://hermanngmeinerschoolfaridabad.org/student-council">
                      Student Council
                    </a>
                  </li>
                  <li>
                    <a href="https://hermanngmeinerschoolfaridabad.org/trips">
                      Trips, Excursions &amp; Field Visits
                    </a>
                  </li>
                  <li>
                    <a href="https://hermanngmeinerschoolfaridabad.org/ncc-scouts">
                      NCC, Scouts &amp; Guides
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="https://hermanngmeinerschoolfaridabad.org/sports">
                  Sport and Fitness
                </a>
              </li>
              <li>
                <a href="https://hermanngmeinerschoolfaridabad.org/infrastructure">
                  Infrastructure and&nbsp;
                </a>
                Facilities
              </li>
              <li>
                <a href="https://hermanngmeinerschoolfaridabad.org/awards">
                  Achievements &amp; Laurels
                </a>
              </li>
              <li>
                <a href="https://hermanngmeinerschoolfaridabad.org/events">
                  Events &amp; Happenings
                </a>
              </li>
              <li>
                <a href="https://hermanngmeinerschoolfaridabad.org/news">
                  News &amp; Media Coverage
                </a>
              </li>
              <li>
                <a href="https://hermanngmeinerschoolfaridabad.org/inspired">
                  Illustrious Alumni
                </a>
              </li>
              <li>
                Parents
                <a href="https://hermanngmeinerschoolfaridabad.org/parents">
                  &nbsp;&amp; Students&apos; Voice
                </a>
              </li>
              <li>
                <a href="https://hermanngmeinerschoolfaridabad.org/admissions">
                  Admissions
                </a>
              </li>
              <li>
                <a href="https://hermanngmeinerschoolfaridabad.org/faculty-staff">
                  Faculty &amp; Staff
                </a>
              </li>
              <li>
                <a href="https://hermanngmeinerschoolfaridabad.org/contact-us">
                  Contact Us
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="https://hermanngmeinerschoolfaridabad.org/contact-us">
              Contact Us
            </a>
          </li>
        </ul>
        <ul>
          <li>
            <a href="https://hermanngmeinereducationalinstitutions.org/about-the-institution">
              About Hermann Gmeiner Educational Institutions
            </a>
          </li>
          <li>
            <a href="https://hermanngmeinerschoolfaridabad.org/nep">
              Academics &amp; Curriculum
            </a>
          </li>
          <li>
            <a href="https://hermanngmeinerschoolfaridabad.org/house-clubs">
              Beyond Curriculum
            </a>
          </li>
          <li>
            <a href="https://hermanngmeinerschoolfaridabad.org/news">
              News &amp; Media Coverage
            </a>
          </li>
          <li>
            <a href="https://hermanngmeinerschoolfaridabad.org/infrastructure">
              Infrastructure &amp; Facilities
            </a>
          </li>
          <li>
            <a href="https://hermanngmeinerschoolfaridabad.org/contact-us">
              Contact Us
            </a>
          </li>
        </ul>
      </div>
      <Footer />
    </div>
  );
}

export default Sitemap;
