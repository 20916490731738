import axios from "axios";
//changes
export const getStudentTCData = async () => {
    try {
        const response = await axios.get(
            
            "https://cors.kyloapps.com//https://eschool.appsosindia.org/views/api/std_tc_list.php?school_code=HGSF"
        );
        return response.data.student_tcinfo;
    } catch (error) {
        console.log(error);
    }
};
